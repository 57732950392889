import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Divider from '@atoms/Divider/Divider';
import Title from '@atoms/Title/Title';
import GenericPages from './GenericPages';

export default function GenericPagesTerms() {
    function renderEnglishContent() {
        return (
            <>
                <p>
                    <span>
                        Last updated on: <strong>01.09.2021</strong>
                    </span>
                </p>
                <Divider spacing="m" invisible />
                <p>
                    These Terms of Use govern the provision and use of the
                    website and platform offered by Aepsy for the mediation of
                    Psychological Providers. These terms of use may be
                    supplemented by special conditions. Special conditions
                    precede these Terms of Use: These Terms of Use as amended
                    from time to time current version and the Privacy Policy in
                    its current version.
                </p>
                <p>Terms of Use.</p>
                <p>
                    Aepsy provides the Website and the Platform only under the
                    unconditional Consent to these Terms of Use. If you do not
                    agree with these Terms of Use, you may not access the
                    Website or use the access the Website or use the Platform.
                    By accessing the Website and using the Platform, you
                    declare, that you have read and understood these Terms of
                    Use in their entirety and agree to be bound by them in their
                    entirety.
                </p>
                <Title tag="h2" size="l">
                    1 Definitions
                </Title>
                <p>
                    <strong>Website</strong>: the website under the domain{' '}
                    <a href="http://www.aepsy.com">www.aepsy.com</a> and all of
                    its subdomains.
                </p>
                <p>
                    <strong>Aepsy</strong>: Aepsy AG, with its registered office
                    in Zurich.
                </p>
                <p>
                    <strong>Platform</strong>: The totality of the services
                    offered by Aepsy. Services for the mediation of
                    psychological providers, including the website as well as
                    the Aepsy app.
                </p>
                <p>
                    <strong>User</strong>: individuals who access the Website
                    and/or use the Platform.
                </p>
                <p>
                    <strong>Psychological providers</strong>: The listed
                    psychotherapists ("therapy") and psychologists
                    ("psychological coaching") on the website and the Platform.
                </p>
                <p>
                    <strong>Counseling session</strong>: The psychological
                    treatment agreed upon within the framework of the platform
                    by the psychotherapists ("therapy") and psychologists
                    ("psychological coaching").
                </p>
                <Title tag="h2" size="l">
                    . 2 Platform
                </Title>
                <Title tag="h3" size="m">
                    2.1 Scope
                </Title>
                <p>
                    The purpose of the platform is to connect users to
                    psychological providers and to facilitate the delivery of
                    counseling sessions:
                </p>
                <ul>
                    <li>
                        The user can register, create a user account, describe
                        symptoms and provide other information. Based on the
                        information provided, the user can be suggested to one
                        or more psychological providers to arrange a counseling
                        session.
                    </li>
                    <li>
                        The user can view the profile pages of the psychological
                        providers, if necessary search for other psychological
                        providers and arrange a first, free telephone
                        conversation and/or a first paid session.
                    </li>
                    <li>
                        The user can arrange with the psychological provider of
                        his choice a Counseling session online or in-person.
                        Aepsy can provide functions such as (video) call or a
                        chat function, in order to facilitate the implementation
                        of counseling sessions. The psychological provider can
                        contact users for whom he has been suggested.
                    </li>
                    <li>
                        The user has access to a dashboard through which he can
                        communicate with the psychological providers and manage
                        his appointments and his user account.
                    </li>
                    <li>
                        Aepsy can provide content on psychological topics on the
                        platform. This content is provided solely for
                        informational purposes and in no way replace the
                        Consultation by a suitably qualified professional.
                    </li>
                </ul>
                <p>
                    The platform is limited exclusively to the services listed
                    under para. 2.1 (listed services). In particular, the
                    provision of the consulting session(s) is not part of the
                    platform and is subject exclusively to the contractual
                    relationship between the psychological provider and the
                    user. Aepsy does not offer the user any therapeutic or
                    medical counseling services.
                </p>
                <Title tag="h3" size="m">
                    2.2 Remuneration
                </Title>
                <p>
                    The platform is basically offered to the user free of
                    charge.
                </p>
                <p>
                    However, the user is aware that the counseling sessions will
                    be charged by the psychological provider. The respective
                    psychological providers pursue their own pricing strategies.
                    Aepsy endeavors to present the remuneration to the user as
                    transparently as possible prior to the agreement of a
                    counseling session. The user is solely responsible for
                    ensuring that he/she can pay for the consultation session(s)
                    and must and must clarify independently whether and to what
                    extent the costs are covered by his/her the costs will be
                    covered by his/her insurance.
                </p>
                <p>
                    Aepsy may also offer a number of premium membership plans,
                    including memberships offered in conjunction with third
                    parties. Some membership plans may have different terms and
                    restrictions, which may be disclosed during the registration
                    process or in other communications.
                </p>{' '}
                <p>.</p>
                <p>
                    The User may pay for certain consulting sessions through the
                    Platform and may choose from the available payment methods.
                    In the gross price of the consulting sessions paid through
                    the platform includes a commission for Aepsy, which enables
                    Aepsy to provide the platform and to and to ensure a secure
                    purchase process. The gross Price is inclusive of the
                    statutory value added taxes.
                </p>
                <Title tag="h2" size="l">
                    3 Duties of the user
                </Title>
                <p>
                    The user undertakes to provide accurate and truthful
                    information when registering and under the agreement of
                    consulting sessions to provide accurate and truthful
                    information.
                </p>
                <p>
                    The user is aware that making an appointment with a
                    psychological provider is binding and any failure to appear
                    at an an agreed appointment must be notified in advance by a
                    cancellation or postponement and it must be communicated to
                    the psychological provider. The user is solely responsible
                    for all financial and non-financial disadvantages caused by
                    a non-appearance or a missed or defective cancellation or
                    postponement.
                </p>
                <p>
                    The user may cancel free of charge the appointment up to 24
                    hours before the consultation session and already paid fees
                    will be refunded. If a cancellation is not made 24 hours
                    prior to the consultation session, the the fees for the
                    consultation session will be charged to the user in full and
                    a refund is excluded.
                </p>
                <p>
                    The user is within the scope of these terms of use as well
                    as repeatedly on the website expressly pointed out that if
                    he / she is in a in a life-threatening situation or any
                    other emergency and / or is in need of urgent assistance,
                    he/she should immediately contact the responsible
                    institutions.
                </p>
                <Title tag="h2" size="l">
                    4 Duties of the psychological provider
                </Title>
                <p>
                    Aepsy offers psychological providers the opportunity to
                    contact Aepsy and if successfully recruited through the
                    Platform to conduct psychological counseling sessions with
                    Users. Aepsy reviews the requests and, if recruitment is
                    successful, creates a personal profile for the psychological
                    providers on the website and the the platform. As part of
                    the recruitment process, psychological providers agree to be
                    accurate and truthful information during the recruitment
                    process. The psychological provider expressly agrees that
                    his personal profile and the information contained therein
                    are publicly vieawable on the platform. The psychological
                    provider is aware and expressly agrees that Aepsy may
                    advertise the platform and its contents and that and its
                    contents and that in this context the personal profile as
                    well as the information contained therein may be publicly
                    viewable outside of the Platform.
                </p>
                <p>
                    The psychological provider is aware that Aepsy exclusively
                    provides mediation services and is in no way responsible for
                    the conduct of the counseling sessions. If a treatment
                    contract is concluded between the psychological provider and
                    the user, this does not result in any obligations for Aepsy
                    and the psychological provider is solely responsible for the
                    treatment of the user.
                </p>
                <p>
                    The psychological provider warrants at the time of
                    registration as well as for and for the entire period during
                    which he/she offers counseling sessions via the platform.
                    offers, to have at least the following training:
                </p>
                <ul>
                    <li>
                        Therapists: federally or foreign recognized title in
                        psychotherapy according to PsyG.
                    </li>
                    <li>
                        Coaches: recognized training qualification in psychology
                        according to PsyG, or the applicable law for coaches
                        based in an EU/EFTA member state.
                    </li>
                </ul>
                <p>
                    The psychological provider also guarantees, at the time of
                    registration as well as for the entire period during which
                    he offers counseling sessions via the Platform offers, to
                    have the licenses and permits required to provide the
                    relevant services.
                </p>
                <Title tag="h2" size="l">
                    5 Restrictions on usage
                </Title>
                <p>
                    In addition to any other restrictions which may be set forth
                    in these Terms of Use or otherwise specified, the following
                    activities in particular are are not permitted:
                </p>
                <ul>
                    <li>
                        Copying, reproducing, modifying, selling, sublicensing
                        and/or otherwise commercialize the platform and website
                        as well as their contents;
                    </li>
                    <li>
                        Removing, altering, circumventing or interfering with
                        any copyright, trademark or other proprietary notices
                        affixed to the Website, the Platform or the content
                        contained thereon;
                    </li>
                    <li>
                        Using the Platform or the Website in any manner that is
                        or may be harmful
                    </li>
                    <li>
                        Use of the Platform or the Website in a manner that
                        interferes with the access of other users or
                        psychological providers
                    </li>
                    <li>
                        Use of the Platform or the Website in a manner contrary
                        to applicable laws and regulations or in a manner that
                        causes damage to the platform or the Website or any
                        natural or legal person any harm
                    </li>
                    <li>
                        Use of malicious software, including, without
                        limitation, viruses, Trojan Horses or other malware;
                    </li>
                    <li>
                        Performing data mining, data harvesting, data
                        extraction, scraping or other similar activities in
                        connection with the Platform or the Website or during
                        the use thereof;
                    </li>
                    <li>
                        Accessing the Platform or the Website through any
                        automated System, including but not limited to spiders,
                        offline readers, robots, etc., or any other means that
                        may place an Infrastructure may place an unreasonable
                        burden on;
                    </li>
                    <li>
                        Use of the Platform or the Website to collect personally
                        identifiable data;
                    </li>
                    <li>
                        Circumventing measures that Aepsy has taken to prevent
                        or restrict access to or use of the Platform or the
                        Website; and may use; and
                    </li>
                    <li>
                        Reverse engineering (or attempting to reverse engineer)
                        any part of the Website, including the derivation of
                        source code or the Creation of derivative works or
                        materials of any kind using the content available on the
                        Website.
                    </li>
                </ul>
                <Title tag="h2" size="l">
                    6 Limitation of liability
                </Title>
                <Title tag="h3" size="m">
                    6.1 Scope of the platform
                </Title>
                <p>
                    Aepsy shall recruit Psychological Providers with reasonable
                    diligence and requires confirmation at the time of
                    recruitment that the Psychological Providers possess the
                    required education, licenses and accreditations pursuant to
                    subsection 4 of these Terms of Use. Aepsy cannot, however,
                    guarantee that the above-mentioned requirements will be met
                    requirements exist for the entire period of time during
                    which the psychological psychological providers are listed
                    on the platform. For the rest the treatment relationship
                    exists solely between the psychological providers and the
                    user and the psychological provider has the full
                    responsibility for the responsibility for the conduct of the
                    counseling session(s). Aepsy offers no guarantee for the
                    quality of the counseling session(s).
                </p>
                <p>
                    Within the framework of the platform, only appointments with
                    psychological providers can be which have been selected in
                    advance by Aepsy and have been admitted to the service.
                    Service have been approved. Within the framework of the
                    platform can therefore not appointments with all
                    psychotherapists and/or psychologists. psychotherapists
                    and/or psychologists. The user is accordingly expressly
                    advised that he or she must use the available industry
                    directories and other sources of information sources of
                    information if he or she wishes to find additional
                    psychological providers. would like to make out.
                </p>
                <p>
                    Aepsy is in no way responsible for appointment cancellations
                    by or Unavailability of psychological providers. As already
                    mentioned in item 3, the user is advised that he / she
                    should should not use the platform and that he / she should
                    immediately contact the competent institutions, if he / she
                    is in an emergency and / or on urgent emergency and / or is
                    in need of urgent assistance. Aepsy can in no case guarantee
                    that the user will be referred in time to a psychological
                    psychological provider or otherwise be helped in a timely
                    manner. can.
                </p>
                <p>
                    Certain features (e.g., video calls or chat features) may be
                    provided by be provided by third parties. Aepsy selects
                    these carefully and obligates them to take all reasonable
                    measures to ensure secure and reliable secure and reliable
                    communication. Users and psychological providers are aware,
                    however, that no mode of transmission can guarantee complete
                    security and reliability and reliability and, accordingly,
                    Aepsy is not responsible for the timely timely and complete
                    transmission of data from User to psychological
                    Psychological Provider and vice versa.
                </p>
                <Title tag="h3" size="m">
                    6.2 Use of the platform and the website
                </Title>
                <p>
                    The Platform and Website, together with all content, data,
                    information and materials contained therein, "<em>as is</em>
                    . " and "<em>as available</em>" made available. All
                    information provided is obtained from sources that have been
                    carefully selected and deemed reliable sources. Aepsy
                    however, makes no warranty, express or implied, warranty of
                    any kind with respect to the timeliness, accuracy accuracy,
                    reliability, completeness or fitness for a particular
                    purpose. Fitness for a particular purpose of the
                    information.
                </p>
                <p>
                    The user is aware that the use of the website and platform
                    require a functioning Internet access. Aepsy guarantees that
                    the platform or the website as well as individual parts
                    thereof will be parts thereof will function and be
                    accessible without error at all times and accordingly and is
                    not liable for the complete faultlessness and continuity of
                    the continuity of the Platform and Website. Aepsy undertakes
                    all reasonable reasonable measures to protect the data of
                    users and psychological psychological providers. However,
                    they are aware that no measures can measures can guarantee
                    complete security and, accordingly Aepsy accepts no
                    responsibility for any loss of data. any loss of data. For
                    more information about how Aepsy personal data can be found
                    in the privacy policy. can be found.
                </p>
                <p>
                    Aepsy disclaims any liability for any and all direct and
                    indirect damages including pure financial losses arising
                    from the use of the platform and Website, to the extent
                    permitted by law. Mandatory legal Provisions remain
                    reserved.
                </p>
                <p>
                    Users and psychological providers agree, Aepsy fully
                    indemnified and held harmless from and against any and all
                    liabilities, costs, claims, causes of action, damages and
                    expenses arising in any way in connection with your breach
                    of any of of any of the provisions of these Terms of Use.
                </p>
                <Title tag="h3" size="m">
                    6.3 Third Party Content and Links
                </Title>
                <p>
                    The Platform and Website may contain third party content and
                    links to third party websites Third Parties. Aepsy assumes
                    no responsibility for and makes no guarantees, warranties or
                    representations of any kind with respect to third party
                    Third Party Websites and their content. Aepsy disclaims any
                    liability in this in this context. Should Aepsy become aware
                    of any infringements of the law the corresponding links or
                    contents will be removed as soon as possible. removed.
                </p>
                <Title tag="h2" size="l">
                    7 Intellectual property
                </Title>
                <p>
                    Aepsy (and any licensors) owns and retains all rights, title
                    and interest in all information and content (including all
                    text, data, graphics and logos) on the website. website.
                    This also includes the design and structure of the website
                    and the platform. Without prior written permission
                    Information, text, graphics, images, video clips,
                    directories, databases, data, listings or software that
                    originate from the website may or software originating from
                    the Website may not be modified, copied, transmitted,
                    distributed, displayed distributed, displayed, performed,
                    reproduced, published, licensed, created derivative works
                    from, transmitted or otherwise used, in whole or in part
                    otherwise used, in whole or in part, for any purpose,
                    commercial or otherwise. commercial or other purposes. All
                    rights are reserved. Systematic retrieval of content from
                    the website for any purpose without any purpose without
                    prior written permission is prohibited.
                </p>
                <p>
                    The user is granted only a limited, non-exclusive,
                    non-transferable, non-commercial and purely for internal
                    purposes, subject to the following purposes only, subject to
                    the limitations provided for in these Terms of Use and any
                    further restrictions, to use the website and the platform.
                    Platform.
                </p>
                <Title tag="h2" size="l">
                    8 Privacy Policy
                </Title>
                <p>
                    The protection of the privacy of users is of central
                    importance. Accordingly, Aepsy only collects personal data
                    from users and psychological providers, insofar as this is
                    necessary for the provision and improvement of the platform
                    and website. Comprehensive information on the collection and
                    processing of personal data by Aepsy can be by Aepsy can be
                    found in the Privacy Policy.
                </p>
                <p>
                    Users are particularly aware that by using the Platform
                    particularly sensitive data about their health may be
                    collected and the may be collected and users expressly
                    consent to this. Further, the the users agree that this data
                    may be transmitted to psychological providers providers,
                    who, in connection with their participation in the platform,
                    undertake to platform, to use these data within the scope of
                    their careful professional professional practice and in
                    compliance with professional secrecy. treat.
                </p>
                <Title tag="h2" size="l">
                    9 Changes
                </Title>
                <p>
                    Aepsy reserves the right to change these terms of use at any
                    time. changes at any time. Amendments will be posted on the
                    Website and may, at Aepsy's Aepsy's discretion, may also be
                    additionally communicated to users and psychological
                    Providers otherwise at the discretion of Aepsy. For existing
                    users and psychological psychological providers shall be
                    deemed to have accepted the changes, unless they have do not
                    expressly reject the changes within 5 days after the changes
                    are posted on the on the website. Aepsy has the right to
                    terminate users and psychological providers who reject
                    changes from the platform at any time. Platform at any time.
                </p>
                <Title tag="h2" size="l">
                    10 Final provisions
                </Title>
                <p>
                    If any provision or provisions of these Terms of Use shall
                    be (in whole or in part) unlawful, void or otherwise
                    unenforceable, the other otherwise unenforceable, the other
                    provisions of these Terms of Terms of Use shall remain in
                    effect mutatis mutandis.
                </p>
                <p>
                    These Terms of Use are governed by Swiss law to the Swiss
                    law, to the exclusion of the conflict of laws provisions and
                    the UN Convention on Contracts for the International Sale of
                    Goods (CISG). Competent for all disputes arising out of or
                    in connection with these these Terms of Use are exclusively
                    the ordinary courts at the registered Aepsy AG's registered
                    office.
                </p>
                <Title tag="h2" size="l">
                    11 Contact
                </Title>
                <p>
                    If you have any questions about these terms of use, please
                    contact please contact:
                </p>
                <p>Aepsy AG</p>
                <p>Seilergraben 53, 8001 Z&uuml;rich</p>
                <p>
                    <a href="mailto:hey@aepsy.com">hey@aepsy.com</a>
                </p>
            </>
        );
    }

    function renderGermanContent() {
        return (
            <>
                <p>
                    <span>
                        Zuletzt aktualisiert am: <strong>01.09.2021</strong>
                    </span>
                </p>

                <Divider spacing="m" invisible />

                <p>
                    Diese Nutzungsbedingungen regeln die Bereitstellung und
                    Nutzung der von Aepsy angebotenen Website und Plattform zur
                    Vermittlung von Psychologischen Anbietern. Diese
                    Nutzungsbedingungen können durch besondere Bedingungen
                    ergänzt werden. Besondere Bedingungen gehen diesen
                    Nutzungsbedingungen: Diese Nutzungsbedingungen in ihrer
                    jeweils aktuellen Fassung sowie Datenschutzrichtlinie in
                    ihrer jeweils aktuellen Fassung.
                </p>
                <p>Nutzungsbedingungen vor.</p>
                <p>
                    Aepsy stellt die Website und die Plattform nur unter der
                    vorbehaltlosen Einwilligung in diese Nutzungsbedingungen zur
                    Verfügung. Sollten Sie mit diesen Nutzungsbedingungen nicht
                    einverstanden sein, dürfen Sie nicht auf die Website
                    zugreifen und die Plattform nicht nutzen. Mit dem Zugriff
                    auf die Website und der Nutzung der Plattform erklären Sie,
                    diese Nutzungsbedingungen vollständig gelesen und verstanden
                    zu haben und erklären sich mit diesen vollumfänglich
                    einverstanden.
                </p>

                <Title tag="h2" size="l">
                    1 Definitionen
                </Title>
                <p>
                    <strong>Website</strong>: Die Website unter der Domain{' '}
                    <a href="http://www.aepsy.com">www.aepsy.com</a> sowie
                    sämtlichen Subdomains.
                </p>
                <p>
                    <strong>Aepsy</strong>: Die Aepsy AG mit Sitz in Zürich.
                </p>
                <p>
                    <strong>Plattform</strong>: Die Gesamtheit der von Aepsy
                    angebotenen Dienstleistungen zur Vermittlung von
                    Psychologischen Anbietern, inklusive der Website sowie der
                    Aepsy-App.
                </p>
                <p>
                    <strong>Nutzer</strong>: Individuen, welche auf die Website
                    zugreifen und / oder die Plattform nutzen.
                </p>
                <p>
                    <strong>Psychologische Anbieter</strong>: Die auf der
                    Website und der Plattform aufgelisteten
                    Psychotherapeuten/Innen («Therapeuten/innen») und
                    Psychologen/innen («Coaches»).
                </p>
                <p>
                    <strong>Beratungssitzung</strong>: Die im Rahmen der
                    Plattform vereinbarte psychologische Behandlung durch die
                    Psychotherapeuten/Innen («Therapie») und Psychologen/innen
                    («psychologisches Coaching»).
                </p>

                <Title tag="h2" size="l">
                    2 Plattform
                </Title>
                <Title tag="h3" size="m">
                    2.1 Umfang
                </Title>
                <p>
                    Die Plattform bezweckt, Nutzer an Psychologische Anbieter zu
                    vermitteln und die Durchführung von Beratungssitzungen zu
                    erleichtern:
                </p>
                <ul>
                    <li>
                        Der Nutzer kann sich registrieren, ein Nutzerkonto
                        anlegen, Symptome schildern und weitere Angaben machen.
                        Auf Grund der Angaben wird dem Nutzer einer oder mehrere
                        psychologische Anbieter zur Vereinbarung einer
                        Beratungssitzung vorgeschlagen.
                    </li>
                    <li>
                        Der Nutzer kann die Profilseiten der psychologischen
                        Anbieter einsehen, allenfalls nach weiteren
                        psychologischen Anbietern suchen und ein erstes,
                        kostenloses Telefongespräch und/oder eine erste bezahlte
                        Sitzung vereinbaren.
                    </li>
                    <li>
                        Der Nutzer kann mit dem psychologischen Anbieter seiner
                        Wahl eine Beratungssitzung vereinbaren und diese je nach
                        Anbieter und Verfügbarkeit online oder physisch
                        wahrnehmen. Aepsy kann Funktionen wie (Video-)Telefonie
                        oder Chat-Funktion zur Verfügung stellen, mit welchen
                        die Durchführung von Beratungssitzungen erleichtert
                        werden kann. Der psychologische Anbieter kann Nutzer
                        kontaktieren, für welche er vorgeschlagen wurde.
                    </li>
                    <li>
                        Der Nutzer hat Zugriff auf ein Dashboard, über welches
                        er mit den psychologischen Anbietern kommunizieren sowie
                        seine Termine und sein Nutzerkonto verwalten kann.
                    </li>
                    <li>
                        Aepsy kann Inhalte zu psychologischen Themen auf der
                        Plattform zur Verfügung stellen. Diese Inhalte werden
                        ausschliesslich zu Informationszwecken bereitgestellt
                        und ersetzen in keinem Fall die Beratung durch eine
                        entsprechend qualifizierte Fachperson.
                    </li>
                </ul>
                <p>
                    Die Plattform begrenzt sich ausschliesslich auf die unter
                    Ziff. 2.1 aufgeführten Dienstleistungen. Insbesondere die
                    Erbringung der im Rahmen der Plattform vereinbarten
                    Beratungssitzung(en) ist nicht Teil der Plattform und
                    unterliegt ausschliesslich dem vertraglichen Verhältnis
                    zwischen dem psychologischen Anbieter und dem Nutzer. Aepsy
                    ist ausschliesslich vermittelnd und unterstützend tätig und
                    bietet dem Nutzer keinerlei therapeutische oder medizinische
                    Beratungsleistungen.
                </p>

                <Title tag="h3" size="m">
                    2.2 Vergütung
                </Title>

                <p>
                    Die Plattform wird dem Nutzer grundsätzlich kostenlos
                    angeboten.
                </p>
                <p>
                    Der Nutzer ist sich jedoch bewusst, dass die
                    Beratungssitzungen dem Nutzer durch den psychologischen
                    Anbieter belastet werden. Die jeweiligen psychologischen
                    Anbieter verfolgen eigene Preisstrategien. Aepsy ist bemüht,
                    die Vergütung dem Nutzer vor der Vereinbarung einer
                    Beratungssitzung möglichst transparent darzustellen. Der
                    Nutzer ist alleinig dafür verantwortlich, dass er / sie die
                    Beratungssitzung(en) bezahlen kann und muss selbstständig
                    abklären, ob und in welchem Ausmass die Kosten von seiner
                    Versicherung getragen werden.
                </p>
                <p>
                    Aepsy kann zudem eine Reihe von
                    Premium-Mitgliedschaftsplänen anbieten, einschliesslich
                    Mitgliedschaften, die in Verbindung mit Dritten angeboten
                    werden. Einige Mitgliedschaftspläne können unterschiedliche
                    Bedingungen und Einschränkungen haben, die bei der
                    Registrierung der Nutzer oder in anderen zur Verfügung
                    gestellten Mitteilungen offengelegt werden.
                </p>
                <p>
                    Der Nutzer kann bestimmte Beratungssitzungen über die
                    Plattform bezahlen und kann dabei aus den verfügbaren
                    Zahlungsmethoden wählen. Im Bruttopreis der über die
                    Plattform bezahlten Beratungssitzungen ist eine Provision
                    für Aepsy enthalten, die es Aepsy ermöglicht, die Plattform
                    bereitzustellen und einen sicheren Kaufvorgang
                    sicherzustellen. Der Bruttopreis versteht sich inklusive der
                    gesetzlichen Mehrwertsteuern.
                </p>

                <Title tag="h2" size="l">
                    3 Pflichten des Nutzers
                </Title>

                <p>
                    Der Nutzer verpflichtet sich, bei seiner Registrierung und
                    bei der Vereinbarung von Beratungssitzungen exakte und
                    wahrheitsgetreue Angaben zu machen.
                </p>
                <p>
                    Der Nutzer ist sich bewusst, dass eine Terminvereinbarung
                    mit einem psychologischen Anbieter verbindlich ist und jedes
                    Nicht-Erscheinen zu einem vereinbarten Termin vorgängig
                    durch eine Absage oder Verschiebung dem psychologischen
                    Anbieter mitgeteilt werden muss. Der Nutzer ist alleinig für
                    sämtliche finanziellen und nicht finanziellen Nachteile
                    verantwortlich, welche ihm aufgrund eines Nicht-Erscheinens
                    oder einer verpassten oder mangelhaften Absage oder
                    Verschiebung entstehen.
                </p>
                <p>
                    Der Nutzer kann den Termin bis 24 Stunden vor der
                    Beratungssitzung kostenfrei stornieren, bereits bezahlte
                    Gebühren werden zurückerstattet. Erfolgt eine Abmeldung
                    nicht bis 24 Stunden vor der Beratungssitzung, so werden dem
                    Nutzer die Gebühren für die Beratungssitzung vollumfänglich
                    verrechnet und eine Rückerstattung ist ausgeschlossen.
                </p>
                <p>
                    Der Nutzer wird im Rahmen dieser Nutzungsbedingungen sowie
                    wiederholt auf der Website ausdrücklich darauf hingewiesen,
                    dass wenn er / sie sich in einer lebensbedrohlichen
                    Situation oder einem sonstigen Notfall befindet und / oder
                    auf dringende Hilfe angewiesen ist, die Plattform nicht
                    nutzen soll und umgehend mit den zuständigen Institutionen
                    Kontakt aufnehmen soll.
                </p>

                <Title tag="h2" size="l">
                    4 Pflichten des psychologischen Anbieters
                </Title>

                <p>
                    Aepsy bietet psychologischen Anbietern die Möglichkeit, mit
                    Aepsy Kontakt aufzunehmen und bei erfolgreicher Rekrutierung
                    über die Plattform mit Nutzern psychologische
                    Beratungssitzungen durchzuführen. Aepsy prüft die Anfragen
                    und erstellt bei erfolgreicher Rekrutierung ein persönliches
                    Profil für die psychologischen Anbieter auf der Website und
                    der Plattform. Die psychologischen Anbieter verpflichten
                    sich, im Rahmen der Rekrutierung exakte und wahrheitsgetreue
                    Angaben zu machen. Der psychologische Anbieter ist
                    ausdrücklich damit einverstanden, dass sein persönliches
                    Profil und die darin enthaltenen Informationen öffentlich
                    auf der Website sowie für registrierte Nutzer auf der
                    Plattform einsehbar ist. Der psychologischen Anbieter ist
                    sich bewusst und erklärt sich ausdrücklich damit
                    einverstanden, dass Aepsy die Plattform und deren Inhalte
                    bewerben kann und dass in diesem Zusammenhang auch das
                    persönliche Profil sowie die darin enthaltenen Informationen
                    möglicherweise öffentlich ausserhalb der Plattform einsehbar
                    sind.
                </p>
                <p>
                    Der psychologischen Anbieter ist sich bewusst, dass Aepsy
                    ausschliesslich Vermittlungsdienste übernimmt und in keiner
                    Weise für die Durchführung der Beratungssitzungen
                    verantwortlich ist. Wird im Rahmen einer Beratungssitzung
                    ein Behandlungsvertrag zwischen dem psychologischen Anbieter
                    und dem Nutzer geschlossen, ergeben sich daraus keinerlei
                    Pflichten für Aepsy und der psychologische Anbieter ist
                    alleinig für die Behandlung des Nutzers verantwortlich.
                </p>
                <p>
                    Der psychologische Anbieter gewährleistet bei der Anmeldung
                    sowie für die gesamte Zeitdauer, während er
                    Beratungssitzungen über die Plattform anbietet, mindestens
                    über folgende Ausbildungen zu verfügen:
                </p>
                <ul>
                    <li>
                        Therapeuten/innen: eidgenössischer oder anerkannter
                        ausländischer Weiterbildungstitel in Psychotherapie
                        gemäss PsyG.
                    </li>
                    <li>
                        Coaches: anerkannter Ausbildungsabschluss in Psychologie
                        gemäss PsyG oder dem anwendbaren Recht bei Coaches mit
                        Sitz in einem EU/EFTA-Mitgliedstaat.
                    </li>
                </ul>
                <p>
                    Der psychologische Anbieter gewährleistet zudem, bei der
                    Anmeldung sowie für die gesamte Zeitdauer, während er
                    Beratungssitzungen über die Plattform anbietet, über die zur
                    Erbringung der entsprechenden Dienstleistungen
                    erforderlichen Lizenzen und Zulassungen zu verfügen.
                </p>

                <Title tag="h2" size="l">
                    5 Nutzungseinschränkungen
                </Title>

                <p>
                    Nebst anderen Einschränkungen, welche in diesen
                    Nutzungsbedingungen oder anderweitig festgelegt sind, sind
                    insbesondere folgende Aktivitäten nicht gestattet:
                </p>
                <ul>
                    <li>
                        Kopieren, Reproduzieren, Modifizieren, Verkaufen,
                        Unterlizenzieren und/oder anderweitiges
                        Kommerzialisieren der Plattform und Website sowie deren
                        Inhalte;
                    </li>
                    <li>
                        Entfernen, Verändern, Umgehen oder Beeinträchtigen von
                        Urheberrechts-, Marken- oder anderen Eigentumshinweisen,
                        die auf der Website, der Plattform oder dem darauf
                        enthaltenen Inhalt angebracht sind;
                    </li>
                    <li>
                        Nutzung der Plattform oder der Website auf eine Weise,
                        die für diese schädlich ist oder sein könnte;
                    </li>
                    <li>
                        Nutzung der Plattform oder der Website auf eine Weise,
                        die den Zugriff anderer Nutzer oder psychologischen
                        Anbieter beeinträchtigt;
                    </li>
                    <li>
                        Nutzung der Plattform oder der Website entgegen den
                        geltenden Gesetzen und Vorschriften oder auf eine Weise,
                        die der Plattform oder der Website oder einer
                        natürlichen oder juristischen Person einen Schaden
                        zufügt oder zufügen kann;
                    </li>
                    <li>
                        Verwendung schädlicher Software, insbesondere von Viren,
                        Trojanischen Pferden oder anderer Malware;
                    </li>
                    <li>
                        Ausführen von Data Mining, Data Harvesting,
                        Datenextraktion, Scraping oder anderen, ähnlichen
                        Aktivitäten im Zusammenhang mit der Plattform oder der
                        Website oder während der Nutzung dieser;
                    </li>
                    <li>
                        Zugriff auf die Plattform oder die Website über ein
                        automatisiertes System, einschliesslich, aber nicht
                        beschränkt auf Spider, Offline-Lesegeräte, Roboter usw.,
                        oder andere Massnahmen, die die Infrastruktur
                        unangemessen belasten können;
                    </li>
                    <li>
                        Nutzung der Plattform oder der Website zur Erfassung
                        personenbezogener Daten;
                    </li>
                    <li>
                        Umgehung von Massnahmen, die Aepsy zur Verhinderung oder
                        Einschränkung des Zugriffs auf oder der Nutzung der
                        Plattform oder der Website verwenden kann; und
                    </li>
                    <li>
                        Rückentwicklung (oder der Versuch zur Rückentwicklung)
                        irgendeines Teils der Website, inklusive der Ableitung
                        des Quellcodes oder der Erstellung abgeleiteter Arbeiten
                        oder Materialien jeglicher Art unter Verwendung des auf
                        der Website verfügbaren Inhalts.
                    </li>
                </ul>

                <Title tag="h2" size="l">
                    6 Haftungsbeschränkung
                </Title>

                <Title tag="h3" size="m">
                    6.1 Umfang der Plattform
                </Title>
                <p>
                    Aepsy rekrutiert die psychologischen Anbieter mit der
                    angemessenen Sorgfalt und verlangt bei der Rekrutierung eine
                    Bestätigung, dass die psychologischen Anbieter über die
                    geforderten Ausbildungen, Lizenzen und Zulassungen gemäss
                    Abs. ‎4 dieser Nutzungsbedingungen verfügen. Aepsy kann
                    jedoch nicht gewährleisten, dass die soeben genannten
                    Voraussetzungen während der gesamten Zeitdauer bestehen,
                    während der die psychologischen Anbieter auf der Plattform
                    aufgelistet sind. Im Übrigen besteht das
                    Behandlungsverhältnis alleine zwischen den psychologischen
                    Anbietern und dem Nutzer und der psychologischen Anbieter
                    hat die volle Verantwortung für die Durchführung der
                    Beratungssitzung(en). Aepsy bietet keinerlei Gewähr für die
                    die Qualität der Beratungssitzung(en).
                </p>
                <p>
                    Im Rahmen der Plattform können nur Termine mit
                    psychologischen Anbietern vereinbart werden, welche
                    vorgängig durch Aepsy ausgewählt und zum Service zugelassen
                    wurden. Im Rahmen der Plattform können daher nicht Termine
                    mit sämtlichen Psychotherapeuten und Psychotherapeutinnen
                    und/oder Psychologinnen und Psychologen vereinbart werden.
                    Der Nutzer wird entsprechend ausdrücklich darauf
                    hingewiesen, dass er die verfügbaren Branchenverzeichnisse
                    sowie weitere Informationsquellen konsultieren soll, wenn er
                    zusätzliche psychologischen Anbieter ausfindig machen
                    möchte.
                </p>
                <p>
                    Aepsy ist in keiner Weise für Terminabsagen durch oder
                    Nichtverfügbarkeit von psychologischen Anbietern
                    verantwortlich. Wie bereits in Ziff. 3 erwähnt, wird der
                    Nutzer darauf hingewiesen, dass er / sie die Plattform nicht
                    benutzen soll und sich umgehend an die zuständigen
                    Institutionen wenden soll, sofern er / sie sich in einem
                    Notfall befindet und / oder auf dringende Hilfe angewiesen
                    ist. Aepsy kann in keinem Fall gewährleisten, dass dem
                    Nutzer rechtzeitig ein psychologischer Anbieter vermittelt
                    oder anderweitig geholfen werden kann.
                </p>
                <p>
                    Bestimmte Funktionen (bspw. Videoanrufe oder Chatfunktionen)
                    können von Dritten bereitgestellt werden. Aepsy wählt diese
                    sorgfältig aus und verpflichtet diese, alle angemessenen
                    Massnahmen zu treffen, um eine sichere und verlässliche
                    Kommunikation sicherzustellen. Nutzer und psychologische
                    Anbieter sind sich jedoch bewusst, dass keine Art der
                    Übertragung eine vollständige Sicherheit und Verlässlichkeit
                    gewährleisten kann und entsprechend haftet Aepsy nicht für
                    die rechtzeitige und vollständige Übermittlung von Daten von
                    Nutzer an psychologischen Anbieter und vice-versa.
                </p>

                <Title tag="h3" size="m">
                    6.2 Nutzung der Plattform und der Website
                </Title>

                <p>
                    Die Plattform und Website werden zusammen mit allen
                    Inhalten, Daten, Informationen und Materialien, die darin
                    enthalten sind, "<em>as is</em>" und "<em>as available</em>"
                    zur Verfügung gestellt. Alle bereitgestellten Informationen
                    werden aus von Aepsy sorgfältig ausgewählten und als
                    zuverlässig eingestuften Quellen bezogen. Aepsy übernimmt
                    aber weder ausdrücklich noch implizit eine Garantie,
                    Zusicherung oder Gewährleistung irgendeiner Art bezüglich
                    der Aktualität, Richtigkeit, Zuverlässigkeit,
                    Vollständigkeit oder Zweckeignung der Informationen.
                </p>
                <p>
                    Der Nutzer ist sich bewusst, dass die Nutzung der Website
                    und Plattform einen funktionierenden Internetzugang
                    erfordern. Aepsy garantiert darüber hinaus nicht, dass die
                    Plattform oder die Website sowie einzelne Teile davon
                    jederzeit fehlerfrei funktionieren und zugänglich sind und
                    haftet entsprechend nicht für die vollständige
                    Fehlerlosigkeit und Kontinuität der Plattform und Website.
                    Aepsy unternimmt sämtliche angemessenen Massnahmen zum
                    Schutz der Daten der Nutzer und psychologischen Anbieter.
                    Diese sind sich jedoch bewusst, dass keine Massnahmen eine
                    vollständige Sicherheit gewährleisten können und
                    entsprechend übernimmt Aepsy keinerlei Verantwortung für
                    einen allfälligen Verlust von Daten. Weitere Informationen
                    dazu, wie Aepsy personenbezogene Daten schützt, können der
                    Datenschutzrichtlinie entnommen werden.
                </p>
                <p>
                    Aepsy lehnt jegliche Haftung für sämtliche direkte und
                    indirekte Schäden inklusive reine Vermögensschäden aus der
                    Nutzung der Plattform und Website, soweit gesetzlich
                    zulässig, ab. Zwingende gesetzliche Bestimmungen bleiben
                    vorbehalten.
                </p>
                <p>
                    Nutzer und psychologischen Anbieter erklären sich damit
                    einverstanden, Aepsy vollumfänglich schadlos zu halten von
                    und gegen jegliche Verbindlichkeiten, Kosten, Forderungen,
                    Klagegründe, Schäden und Ausgaben, die in irgendeiner Weise
                    im Zusammenhang mit Ihrem Verstoss gegen eine der
                    Bestimmungen dieser Nutzungsbedingungen entstehen.
                </p>

                <Title tag="h3" size="m">
                    6.3 Inhalte und Links Dritter
                </Title>

                <p>
                    Die Plattform und Website können Inhalte Dritter sowie Links
                    zu Websites Dritter enthalten. Aepsy übernimmt keine
                    Verantwortung und gibt keinerlei Garantien, Gewährleistungen
                    oder Zusicherungen in Bezug auf Websites Dritter und deren
                    Inhalte. Aepsy lehnt jegliche Haftung in diesem Zusammenhang
                    ab. Sollten Aepsy Kenntnis von Rechtsverletzungen erhalten,
                    werden die entsprechenden Links oder Inhalte
                    schnellstmöglich entfernt.
                </p>

                <Title tag="h2" size="l">
                    7 Geistiges Eigentum
                </Title>

                <p>
                    Aepsy (sowie allfällige Lizenzgeber) besitzt und behält alle
                    Rechte, Titel und Interessen an allen Informationen und
                    Inhalten (einschliesslich aller Texte, Daten, Grafiken und
                    Logos) auf der Website. Davon erfasst ist auch der Aufbau
                    und die Struktur der Website und der Plattform. Ohne
                    vorherige schriftliche Genehmigung dürfen Informationen,
                    Texte, Grafiken, Bilder, Videoclips, Verzeichnisse,
                    Datenbanken, Daten, Auflistungen oder Software, die von der
                    Website stammen, weder ganz noch teilweise verändert,
                    kopiert, übertragen, verteilt, angezeigt, vorgeführt,
                    reproduziert, veröffentlicht, lizenziert, davon abgeleitete
                    Werke erstellt, übertragen oder anderweitig genutzt werden,
                    weder ganz noch teilweise, weder für kommerzielle noch für
                    andere Zwecke. Sämtliche Rechte bleiben vorbehalten. Der
                    systematische Abruf von Inhalten von der Website für
                    jeglichen Zweck ohne vorherige schriftliche Genehmigung
                    untersagt.
                </p>
                <p>
                    Der Nutzer erhält lediglich ein beschränktes, nicht
                    ausschliessliches, nicht übertragbares, nicht kommerziell
                    und rein für interne Zwecke nutzbares Recht, vorbehaltlich
                    der in diesen Nutzungsbedingungen vorgesehenen und
                    allfälligen weiteren Einschränkungen, die Website und
                    Plattform zu nutzen.
                </p>

                <Title tag="h2" size="l">
                    8 Datenschutz
                </Title>

                <p>
                    Der Schutz der Privatsphäre der Nutzer ist für Aepsy von
                    zentraler Bedeutung. Aepsy erhebt entsprechend nur
                    personenbezogene Daten von Nutzern und psychologischen
                    Anbietern, sofern dies zur Bereitstellung und Verbesserung
                    der Plattform und Website nötig ist. Umfassende
                    Informationen zur Erhebung und Verarbeitung von
                    personenbezogenen Daten durch Aepsy können der
                    Datenschutzrichtlinie entnommen werden.
                </p>
                <p>
                    Die Nutzer sind sich insbesondere bewusst, dass durch die
                    Nutzung der Plattform besonders schützenswerte Daten über
                    ihre Gesundheit erhoben werden können und die Nutzer stimmen
                    dem ausdrücklich zu. Weiter stimmen die Nutzer zu, dass
                    diese Daten an psychologischen Anbieter übermittelt werden,
                    welche sich im Zusammenhang mit ihrer Teilnahme an den
                    Plattform dazu verpflichten, diese Daten im Rahmen ihrer
                    sorgfältigen beruflichen Ausübung und unter Wahrung des
                    Berufsgeheimnisses vertraulich zu behandeln.
                </p>

                <Title tag="h2" size="l">
                    9 Änderungen
                </Title>

                <p>
                    Aepsy behält sich das Recht vor, diese Nutzungsbedingungen
                    jederzeit zu ändern. Änderungen werden auf der Website
                    aufgeschaltet und können im Ermessen von Aepsy auch
                    zusätzlich den Nutzern und psychologischen Anbietern
                    anderweitig mitgeteilt werden. Für bestehende Nutzer und
                    psychologische Anbieter gelten Änderungen als angenommen,
                    sofern diese die Änderungen nicht innert 5 Tagen nach
                    Aufschaltung der Änderungen auf der Website ausdrücklich
                    ablehnen. Aepsy hat das Recht, Nutzer und psychologische
                    Anbieter, welche Änderungen ablehnen, jederzeit von der
                    Plattform auszuschliessen.
                </p>

                <Title tag="h2" size="l">
                    10 Schlussbestimmungen
                </Title>

                <p>
                    Sollte eine oder mehrere Bestimmungen dieser
                    Nutzungsbedingungen (teilweise oder ganz) rechtswidrig,
                    ungültig oder anderweitig nicht durchsetzbar sein, bleiben
                    die anderen Bestimmungen dieser Nutzungsbedingungen mutatis
                    mutandis in Kraft.
                </p>
                <p>
                    Diese Nutzungsbedingungen unterstehen schweizerischem Recht
                    unter Ausschluss der konfliktrechtlichen Bestimmungen sowie
                    des UN-Übereinkommens über den internationalen Warenkauf
                    (CISG). Zuständig für alle Streitigkeiten aus oder in
                    Zusammenhang mit diesen Nutzungsbedingungen sind
                    ausschliesslich die ordentlichen Gerichte am Sitz der Aepsy
                    AG.
                </p>
                <Title tag="h2" size="l">
                    11 Kontakt
                </Title>

                <p>
                    Wenn Sie Fragen zu dieser Nutzungsbedingungen haben, wenden
                    Sie sich bitte an:
                </p>
                <p>Aepsy AG</p>
                <p>Seilergraben 53, 8001 Z&uuml;rich</p>
                <p>
                    <a href="mailto:hey@aepsy.com">hey@aepsy.com</a>
                </p>
            </>
        );
    }

    const { language } = useI18next();

    function renderContent() {
        switch (language) {
            case 'en':
                return renderEnglishContent();
            default:
                return renderGermanContent();
        }
    }

    return <GenericPages>{renderContent()}</GenericPages>;
}
